<template>
    <div class="container pt-5">
      <p><strong>How much does Pet Check cost?</strong></p>
      <p>&nbsp;</p>
      <p>Have a question? Want to learn more about our patented software or about the dog walking industry?</p>
      <p> We’re here to help. Call us, email us, or fill out this contact us form now.</p>
      <p>&nbsp;</p>
      <div class="d-flex justify-content-center">
        <i class="fas fa-mobile-alt mx-2"></i>
        <p>312-953-5999</p>
      </div>
      <p>&nbsp;</p>

      <div class="d-flex justify-content-center">
        <i class="fas fa-envelope-open-text mx-2"></i>
        <a href="#">info@petchecktechnology.com</a>
      </div>
      <p>&nbsp;</p>

    </div>
</template>

<script>
export default {
  name: "Contact",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>