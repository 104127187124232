<template>
  <div class="container pt-5">
    <p><strong>REFUND AND CANCELLATION POLICY</strong></p>
    <p>&nbsp;</p>

    <p class="text-start px-1">PET CHECK TECHNOLOGY LICENSEE AND LICENSEE CUSTOMER REFUND POLICY</p>
    <p class="text-start px-1">As of 3/1/2012</p>
    <p>&nbsp;</p>
    <p class="text-start px-1">In the event that Pet Check Technology is required to refund any sums charged or debited from Licensee&rsquo;s credit card or bank account or Licensee&rsquo;s customer&rsquo;s credit card or bank account, such sums shall be refunded as follows: (1) If paid with a bank account, the money will be transferred back to Licensee&rsquo;s or Licensee&rsquo;s customer&rsquo;s account within seven (7) days after Licensee&rsquo;s submission of the refund form contained in Licensee&rsquo;s Pet Check Technology account; or (2) if paid with a accredit card, the money will go back to such party&rsquo;s credit card as soon as practical after Licensee&rsquo;s submission of the Refund Form contained&nbsp; in Licensee&rsquo;s Pet Check Technology account. <u>&nbsp;&nbsp;</u></p>
    <p>&nbsp;</p>
    <p class="text-start px-1"> PET CHECK TECHNOLOGY LICENSEE CANCELLATION POLICY</p>
    <p class="text-start px-1">As of 8/1/2011</p>
    <p>&nbsp;</p>
    <p class="text-start px-1">TERM AND TERMINATION.</p>
    <p class="text-start px-3">(A) The initial term of the Agreement shall be one (1) year from the Effective Date (the &ldquo;Initial Term&rdquo;) and will, unless otherwise terminated pursuant to Subsection B of this Section 4, automatically renew for subsequent one (1) year terms (the &ldquo;Renewal Terms&rdquo;) unless either party submits written notice to the other of its intent not to renew no later than sixty (60) days prior to the end of the then current term.</p>
    <p>&nbsp;</p>
    <p class="text-start px-3">(B) TERMINATION.</p>
    <p class="text-start px-4">(i) Termination by Licensee.&nbsp; Licensee may terminate this Agreement upon a material breach by Company of any of Company&rsquo;s obligations under this Agreement if Company does not cure said breach within thirty (30) days following Company&rsquo;s receipt of Licensee&rsquo;s written notice to Company describing the breach in sufficient detail; provided, however, that &ldquo;Material breach&rdquo; shall not include any occurrence or event attributable to, arising out of or relating to any event not within Company&rsquo;s control, or caused directly or indirectly by&nbsp; Licensee or any third party.</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(ii) Termination by Company.&nbsp; Company may terminate this Agreement at any time for any reason at Company&rsquo;s sole discretion upon fifteen (15) days written notice, provided however, that in the event such termination is due to Licensee&rsquo;s failure to make payments due to Company, Company shall be permitted to terminate upon five (5) days written notice.</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(iii) Termination by Bankruptcy, Insolvency or Failure to Conduct Business.&nbsp; Either party may terminate this agreement immediately upon written notice in the event the other party files a petition of any type as to the bankruptcy, is declared bankrupt, becomes insolvent, makes an assignment for the benefit of creditors, goes into liquidation or receivership, otherwise loses legal control of its business involuntarily or otherwise states in writing its intent to discontinue its business in the normal course.&nbsp;&nbsp;</p>
    <p>&nbsp;</p>
    <p class="text-start px-3">(C) Effect of Termination. Immediately upon termination of this Agreement:</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(i) all rights granted by Company to Licensee shall terminate;</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(ii) Licensee&rsquo;s access to the Licensed Products and Methods and Website shall terminate;</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(iii) Licensee shall: (a)&nbsp; discontinue all use of the Company&rsquo;s Intellectual Property including but not limited to the Licensed Products and Methods as well as trademarks (b) return to Company all copies and derivations thereof and any other Proprietary Items, as defined in Section 14(D) of this Agreement, then in Licensee&rsquo;s possession, custody and control, and (c) give written notice to Company representing, warrantying and certifying that it has not retained, copied or transferred to any third party any of the Licensed Products and Methods; and</p>
    <p>&nbsp;</p>
    <p class="text-start px-4">(iv) Company shall send electronic notification to users of Company&rsquo;s Licensed Products and Methods which were registered by, or in connection with Licensee&rsquo;s Services.&nbsp;</p>
    <p>&nbsp;</p>
    <p class="text-start px-3">(D) Licensee is responsible for establishing their own term and termination policy with their customer base. This includes but is not limited to a service cancellation policy clearly written and provided to their customers.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "RefundAndCancellation",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>