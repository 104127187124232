<template>
  <div class="home">
    <div class="container" >
      <div class="row justify-content-center">
        <div class="col  description-div">
          <p>Pet Check manages every aspect of your dog walking and pet sitting business</p>
          <p class="second-description">
            Pet Check delivers fail-safe scheduling, verified staff visits through our patented barcode scanning technology
            with real-time pet owner alerts, gps tracking, invoicing, credit card processing and business reports.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-5 border"></div>
      </div>
      <div class="row justify-content-between">
        <div class="col-md-3 col-sm-12 money-card">
          <h5>Make More Money</h5>
          <p>
            Pet Check makes your business more efficient and more competitive in the marketplace, allowing you to grow your business faster.
          </p>
          <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
        </div>
        <div class="col-md-3 col-sm-12 money-card">
          <h5>Easy To Use</h5>
          <p>
            Our comprehensive dog walking and pet sitting software is easy for you, your staff, and your customers to use.
          </p>
          <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
        </div>
        <div class="col-md-3 col-sm-12 money-card">
          <h5>Accountability For All</h5>
          <p>
            You want to make sure your dog walkers and pet sitters are doing their job safety.
            Your customers want to know their pets are safe and being cared for properly.
          </p>
          <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
        </div>
      </div>
    </div>
    <div class="business-div">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div>
              <div class="business-card">
                <div class="icon-div">
                  <i class="fas fa-2x fa-chart-bar"></i>
                </div>
                <div class="cards-staff">
                  <h6>For Business Owners</h6>
                  <p>Manage every aspect of your business with confidence, including your stagg and customers.</p>
                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
                </div>
              </div>
              <div class="business-card">
                <div class="icon-div">
                  <i class="far fa-2x fa-check-circle"></i>
                </div>
                <div class="cards-staff">
                  <h6>For Staff</h6>
                  <p>Keep dog walkers and pet sitters up to the second on their ever-changing and sometimes hectic schedules. </p>
                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
                </div>
              </div>
              <div class="business-card">
                <div class="icon-div">
                  <i class="far fa-2x fa-envelope"></i>
                </div>
                <div class="cards-staff">
                  <h6>For Pet Owners</h6>
                  <p>Pets are members of the family. Give them the accountability they deserve. </p>
                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Learn More</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 digital-pic-div">
            <img src="../assets/desctop-mobile.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="background">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6  testimonials-div">
            <h2><a href="https://dashboard.petchecktechnology.com" target="_blank">Testimonials</a></h2>
            <p>I love getting an email every day that tells me when and where Lucy, my dog,walked.</p>
            <div class="testimonials-card">
              <div class="user-icon-div">
                <i class="far fa-3x fa-user"></i>
              </div>
              <div>
                <p>Greg M.</p>
                <p>Washington, D.C. Pet owner</p>
                <a href="https://dashboard.petchecktechnology.com" target="_blank">20 walks per month</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background1">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 col-xs-12">
              <p >The only pet care management software built by pet pros.</p>
          </div>
          <div class="col-md-3 col-xs-12">
              <button>
                <a href="https://dashboard.petchecktechnology.com" target="_blank">SIGN UP NOW</a>
              </button>
            </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }

}
</script>
<style>
p{
  margin-bottom: 2px!important;
}
a{
  text-decoration: none!important;
  color: #03659e!important;
}


button{
  color: #f9f9f9;
  width: fit-content;
  background: #f44606;
  border-radius: 20px!important;
  border: none;
  padding: 5px 15px;
  font-weight: bold;
}

button a{
  color: #f9f9f9!important;
}

.row{
  padding: 25px 0;
}
.description-div{
  max-width: 860px!important;
  margin: 0 auto;
  font-size: 17px;
}
.border{
  border: 1px solid #80808057;
}

.money-card{
  text-align: start;
}

.business-div{
  background: #f9f9f9;
  border-bottom: 2px solid #80808057;
}
.background{
  background: #f5f5f5;
}
.background1{
  background: url("../assets/background1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #f9f9f9;

}
.business-card{
  display: flex;
  justify-content: space-around;
}
.icon-div{
  width: 15%;
  padding-top: 20px;
}
.fa-2x, .fa-3x{
  color: #b4b4b4;
}
.cards-staff{
  width: 80%;
  text-align: start;
  margin-bottom: 13px;
}

.testimonials-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials-div > p{
  font-size: 17px;
  margin-bottom: 15px!important;
}
.testimonials-card{
  display: flex;
  text-align: start;
  margin-bottom: 30px;
}
.user-icon-div{
  padding: 16px;
}

.background1-div> p{
  font-size: 20px;
}

.seen-on-div > div{
  width: 80px;
  border: 1px solid #80808057;
}

@media (max-width: 550px) {
  .digital-pic-div{
    display: none;
  }
}

</style>