<template>
  <div class="container pt-5">
    <p><strong>PRICING</strong></p>
    <p>&nbsp;</p>
    <p class=" px-1">Instead of offering a limiting one-size-fits-all pricing model,<br> we break down the services to fit individual needs, which differ from business to business.</p>
    <p>&nbsp;</p>

  </div>
</template>

<script>
export default {
  name: "Pricing",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>