<template>
  <div class="container pt-5">
    <p><strong>PETCHECKTECHNOLOGY.COM PRIVACY POLICY</strong></p>
    <p>&nbsp;</p>
    <ol>
      <li class="text-start">
        Pet Check Technology, LLC (&ldquo;Pet Check&rdquo; or &ldquo;us&rdquo;
        or &ldquo;we&rdquo;) operates the website www.petchecktechnology.com and
        related digital and mobile programs and applications (collectively the
        &ldquo;Sites&rdquo;). By registering with the Sites or otherwise
        utilizing the Sites, you are confirming that you have read, understood
        and agree to the provisions of this Privacy Policy and the Terms of
        Service for use thereof.&nbsp; We may amend this policy from time to
        time and at any time by posting a revised version on the Site.&nbsp; The
        revised version will be effective at the time we post it, and you
        consent and agree to be bound therein.&nbsp;
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="2">
      <li class="text-start">
        <strong>Our Commitment to Privacy:</strong> Your privacy is important to
        us and this Privacy Policy is designed to help you understand how we
        collect and use the personal and other information you decide to share,
        and to help you make informed decisions when using the Sites. However,
        our Privacy Policy only applies to online data collection and does not
        apply to information that we collect either off-line or through other
        means.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="3">
      <li class="text-start">
        <strong>Information We Collect and How We Use It:</strong>
      </li>
    </ol>
    <p class="text-start px-4">
      a. <u> Personally Identifiable Information:&nbsp; </u>
    </p>
    <p class="px-4">
      We collect information which is personal and identifiable to you, which
      you knowingly choose to disclose that is collected when you register to
      use the Site including your name, e-mail, contact details, business
      details, and depending on the user valid credit card or banking
      information for use of the Sites.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-4">
      b. <u>Non-Personally Identifiable Information:</u>
    </p>
    <p class="px-4">
      i. <u>In General:</u> We may also collect information through technology
      that does not identify you personally in order to make our site more
      interesting and useful.&nbsp; This information may also be used to
      optimize website performance within a given browser and increase the speed
      of navigation.
    </p>
    <p>&nbsp;</p>
    <p class="px-3">
      ii. <u>Cookies:</u> We may use a standard feature in browser software
      called &ldquo;cookies&rdquo; to enhance your experience with the
      Sites.&nbsp; Cookies are small files that your web browser places on your
      hard drive for record-keeping purposes.&nbsp;&nbsp; By showing how and
      when visitors use this website, cookies help us identify how many unique
      users visit this website and track user trends and patterns.&nbsp; They
      also function to prevent you from having to re-enter information required
      to use this website.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p class="px-4">
      1. <u>Use of Cookies for Targeted Advertising:</u> In some instances,
      these cookies may be used by us or other web sites during your future
      navigation of the internet to target specific advertisements to you based
      on your previous use of the Sites.&nbsp; The Sites may also read cookies
      already stored within your browser to identify non-personal information
      about you and communicate this information to other advertisers.&nbsp; All
      cookies are established on a per session basis, and are not permanently
      stored on your machine.&nbsp;
    </p>
    <p>&nbsp;</p>
    <p class="px-4">
      2. <u>Additional Information on Cookies:</u> Information on deleting or
      controlling cookies is available at
      <u
        ><a
          href="../../../../../../Documents%20and%20Settings/Doug/AppData/Local/Microsoft/Windows/Temporary%20Internet%20Files/Content.Outlook/EPN04MHC/www.AboutCookies.org"
          >www.AboutCookies.org</a
        ></u
      >.&nbsp; Please note that by deleting our cookies or disabling future
      cookies you may not be able to access certain areas or features of our
      site.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-4">
      c. <u>How this information is used by us:</u>&nbsp; We may use the
      collected information to (i) process enrollment requests or access
      requests for the Sites; (ii) provide you with services on the &nbsp;Sites;
      (iii) process transactions and send notices related thereto; (iv) prevent
      potentially prohibited or illegal activities and enforce any other
      agreements or terms of use between you and Pet Check; (iv) deliver
      targeted marketing, service update notices and promotional offers based on
      your communication preferences; (v) use such information to help us
      develop, deliver and improve our products, services, content or
      advertising; (vi) share information with you; (vii) compare information
      for accuracy, verify it with third parties or otherwise analyze trends or
      lean about user behavior on the Sites and to gather demographic
      information about our user base as a whole.&nbsp; In some cases an outside
      company may be contracted by us to fulfill a specific request or
      obligation to you. We may occasionally use your name and email address to
      send you notifications regarding new services offered by us, our
      subsidiaries, affiliates and related companies which we think you may find
      valuable.&nbsp; If at any time you would like us to remove you from our
      email or postal mailing lists, feel free to contact us at
      info@petchecktechnology.com and please be sure to include your email
      address, full name, password and in the subject heading place the words
      &ldquo;Opt-Out&rdquo;.&nbsp; Please note that it may take up to 96 hours
      to remove your information from our lists.&nbsp;&nbsp; &nbsp;
    </p>
    <p><strong>&nbsp;</strong></p>
    <p class="text-start px-4">
      d.<u>Sharing Information with Third Parties:</u>&nbsp; We sometimes
      contract with third parties to assist us in maintaining and managing our
      user information, to fulfill promotions, to communicate with our users and
      for other purposes where we believe such sharing is of benefit or legally
      required or otherwise permitted by you.&nbsp; For Example:
    </p>
    <p><strong>&nbsp;</strong></p>
    <p class="px-5">
      i. We may provide information to service providers to help us bring you
      the services we offer.&nbsp; Specifically, we may use third parties to
      facilitate our business, such as to host the service at a co-location
      facility for servers, to send out email updates about the Sites, to remove
      repetitive information from our user lists, to process payments for
      products or services, or to provide search results or links (including
      sponsored links).&nbsp; In connection with these offerings and business
      operations, our service providers may have access to your personal
      information for use for a limited time in connection with these business
      activities.&nbsp; If we use third parties for the processing of any
      personal information, we will make reasonable efforts to implement
      contractual and technical protections limiting the use of that information
      to our specified purposes.
    </p>
    <p>&nbsp;</p>
    <p class="px-5">
      ii. You may be presented with an opportunity to receive information and/or
      marketing offers from third party(s). If you do not agree to have your
      personal information shared, feel free to contact us by contacting
      customer service via email at info@petchecktechnology.com and place the
      words &ldquo;Opt-out&rdquo; in the subject heading and your personal
      information will not be shared with such third party(s).&nbsp; If you do
      agree to have your personal information shared, your personal information
      will be disclosed to such third party(s) and all information you disclose
      will be subject to the privacy policy and practices of such third
      party(s). We are not responsible for the privacy policies and practices of
      such third party(s) and, therefore, you should review the privacy policies
      and practices of such third party(s) prior to agreeing to receive such
      information from third party(s). If you later decide that you no longer
      want to receive communication from a third party, you will need to contact
      that third party directly.
    </p>
    <p>&nbsp;</p>
    <p class="px-5">
      iii. To the extent it is permitted by the law, we may share, rent, sell or
      otherwise provide your personal information or other information collected
      online with unrelated third parties for marketing or any other lawful
      purposes.&nbsp; If you do not agree to have your personal information or
      other information collected online shared with third parties for these
      purposes, feel free to contact us by contacting customer service via email
      at 1341 West Fullerton Avenue PMB 365 Chicago, IL&nbsp; 60614 and placing
      the words &ldquo;Opt-out&rdquo; in the subject heading.&nbsp;&nbsp;&nbsp;
    </p>
    <p>&nbsp;</p>
    <p class="px-5">
      iv. We may be required to disclose user information pursuant to lawful
      requests, such as subpoenas or court orders, or in compliance with
      applicable laws.&nbsp; We do not reveal information until we have a good
      faith belief that an information request by law enforcement or private
      litigants meets applicable legal standards.&nbsp; Additionally, we may
      share account or other information when we believe it is necessary to
      comply with law, to protect our interests or property, to prevent fraud or
      other illegal activity perpetrated through the Sites, or to prevent
      imminent bodily harm.&nbsp; This may include sharing information with
      other companies, lawyers, agents, or government agencies.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-4">
      e.<u>Information We Collect and How We Use it:</u>&nbsp; We sometimes
      contract with third parties to assist us in maintaining and managing our
      user information, to fulfill promotions, to communicate with our users and
      for other purposes where we believe such sharing is of benefit or legally
      required or otherwise permitted by you.&nbsp; For Example:
    </p>
    <p>&nbsp;</p>

    <ol type="i" class="text-start px-5">
      <li>
        <b>Location Services:</b> Your Location. For Pet Care Providers, you can
        opt out of tracking your location. Your location is only tracked during
        the time you are scanned into an appointment and the time you scan out
        of an appointment.
      </li>
      <li class="mt-4">
        <b>Collection of Geolocation Data:</b> PetCheck for Staff app collects
        precise geolocation data from your mobile device while the app is
        running in the background. This data includes your real-time location
        and movement information, and is only collected during the appointment
        visit and not after the appointment is completed.
      </li>
      <li class="mt-4">
        <b>Purpose of Geolocation Data Collection:</b>
      </li>
      We collect geolocation data for the following purposes:
      <ol class="mt-2">
        <li>
          To provide location-based services, such as tracking staff movements
          and ensuring they are at the correct location for service.
        </li>
        <li>
          To enhance the accuracy and reliability of our service delivery.
        </li>
        <li>To ensure the safety and security of our staff and clients.</li>
        <li>To improve our app’s functionality and user experience.</li>
      </ol>
      <li class="mt-4">
        <b>Sharing of Geolocation Data:</b>
      </li>
      We collect geolocation data for the following purposes:
      <ol class="mt-2">
        <li>
          Internal team members who need the data to perform their job
          functions.
        </li>
        <li>
          Third-party service providers who assist us in providing our services
          (e.g., mapping and navigation services).
        </li>
      </ol>
      <li class="mt-4">
        <b>User Control and Data Retention:</b>
      </li>
      You have control over the collection of geolocation data:
      <ol class="mt-2">
        <li>
          You can disable geolocation tracking in your device settings, but this
          may limit the functionality of our app.
        </li>
        <li>
          We retain geolocation data only as long as necessary to fulfill the
          purposes outlined above or as required by law.
        </li>
      </ol>
      <li class="mt-4">
        <b>Data Security:</b>
      </li>
      We take the security of your geolocation data seriously and implement
      appropriate technical and organizational measures to protect it from
      unauthorized access, disclosure, or misuse.
    </ol>
    <p>&nbsp;</p>

    <ol start="4">
      <li>
        <strong>Third Party Advertising: &nbsp;</strong>Ads that appear on the
        Sites can be served directly to users by third party advertisers.&nbsp;
        They may automatically receive your IP address in such cases.&nbsp;
        These third party advertisers may also download cookies to your
        computer, or use other technologies such as JavaScript and web beacons
        (also known as 1x1 gifs) to measure the effectiveness of their ads and
        to personalize advertising content.&nbsp; Using these techniques allows
        the advertising network to recognize your computer each time they send
        you an ad in order to measure the effectiveness of their ads and to
        personalize advertising content.&nbsp; In this way, they may compile
        information about where you or individuals using your computer saw their
        advertisements and determine which advertisements are clicked on. Pet
        Check does not have access to or control of the cookies that may be
        placed by the third party advertisers.&nbsp; This privacy policy covers
        the use of cookies by Pet Check and does not cover the use of cookies or
        other tracking technologies by any of its advertisers.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="5">
      <li>
        <strong>Our Commitment to Children&rsquo;s Privacy:</strong> Protecting
        the privacy of the very young is extremely important to us. No part of
        our website is structured to attract anyone under 13.&nbsp; We never
        collect or maintain information at our website from those under the age
        of 13.&nbsp; If we obtain actual knowledge that we have collected
        information about a child under the age of 13, that information will be
        immediately deleted from our records.
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="6">
      <li>
        <strong>International Users:</strong> Our computer systems are currently
        based in the United States where data protection requirements and
        privacy regulations may be different than other parts of the
        world.&nbsp; If you create an online account on the Sites as a visitor
        or from outside the United States, you will have agreed to the terms of
        this Privacy Policy and our Terms of Use, you will have consented to
        processing of all such information in the United States, and you will
        have agreed that your personal information will be subject to the
        requirements of the privacy regulations of the United States, which may
        not offer an equivalent level of protection as certain other
        countries.&nbsp;
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="7">
      <li>
        <strong>Security and Data Storage:</strong> We employ procedural and
        technological security measures that are designed to safeguard your
        personal information from loss, unauthorized access, disclosure,
        alteration or destruction.
      </li>
    </ol>
    <p><strong>&nbsp;</strong></p>
    <ol start="8">
      <li>
        <strong>Changes to this Privacy Policy:</strong> This privacy policy
        describes the types of information we currently collect, and the ways we
        use and protect that information. From time to time, we may collect
        different types of information and use that information in different
        ways.&nbsp; For example, when we add features or services to our Sites,
        we may edit this policy to explain the new practices.&nbsp; Because
        protecting your privacy is very important to us, we do not expect major
        changes in policy.&nbsp;
      </li>
    </ol>
    <p>&nbsp;</p>
    <ol start="9">
      <li>
        <strong>Questions about Privacy Policy or Concerns:</strong> If you have
        any questions or concerns regarding this Privacy Policy, you should
        contact us by emailing info@petchecktechnology.com and provide
        &ldquo;privacy policy inquiry&rdquo; in the subject heading, or writing
        to us at Pet Check Technology, LLC, attn: Privacy Department 1341 West
        Fullerton Avenue PMB 365 Chicago, IL 60614.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  created() {
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped></style>
