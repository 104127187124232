<template>
  <div class="container pt-5">
    <p><strong>Pet Check For Business</strong></p>
    <p>&nbsp;</p>
    <p class="text-start px-1">   Manage your entire business with confidence.</p>
    <p class="text-start px-1">
      We’ve been in the dog walking and pet sitting business since 2003 and understand the needs of the industry. With Pet Check you can with confidence manage every aspect of your business, including your staff and customers.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1"> Business Features:</p>
    <p class="text-start px-3"> •	Comprehensive tools for scheduling</p>
    <p class="text-start px-3"> •	Verify that staff appointments are carried out correctly</p>
    <p class="text-start px-3"> •	Automated email and mobile updates to pet owners verifying the care of their pet(s)</p>
    <p class="text-start px-3"> •	Robust invoicing tools</p>
    <p class="text-start px-3"> •	Optional credit card processing tools</p>
    <p class="text-start px-3"> •	Easy-to-read business reports</p>
    <p class="text-start px-3"> •	Simplify administrative processes and gain more control of the business</p>
    <p class="text-start px-3"> •	Gain more time to concentrate on growing your business</p>
    <p>&nbsp;</p>

    <p class="text-start px-1">FEATURES</p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Accountability – All of our features are designed to give pet owners and pet care company staff access to all the information they need to make sure all pets are happy and safe.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Fail-safe Scheduling – Flexible and accurate scheduling, featuring drag and drop technology, allows you to set one appointment or a series of appointments. Real-time dog walker and pet sitter schedules on their mobile app reduce miscommunications while making planning easy. Get Pet Check today and empower your staff to help them focus on wowing your customers.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Business Owner Email Alerts - If your dog walkers or pet sitters are running late, you will be notified by email to help stay on track.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Verified Check-ins – Your staff check in and out of every residence using the Pet Check mobile app, giving both you and your customers the exact time of care. Your customers will have the peace of mind knowing their pet was cared for and they won’t have to wait until they get home to read a checklist to see the details of the visit.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Pet Owner Alerts – Upon arrival and departure of every appointment, pet owners receive an email or a mobile alert with details including photos and notes – a highlight of many pet owners’ days.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      GPS Visit Tracking – For pet owners with walkable animals, GPS maps ensure that there will be no more wondering about where the appointment took place. Keep up with the demands of the pet owner and show them what a great job you and your staff do for them.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Pet Photos – Sitters can send photos to pet owners in the daily email, offering a glimpse to owners of how happy and safe their pets are on the visit.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Easy Account Management – Pet owners can easily manage their account, including their schedule and invoicing.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Flexible Billing – Our billing features save you time, which means you can focus on what’s important to you. For example, dog walking and pet sitting companies can choose between weekly, bi-weekly, or monthly customer invoicing. They also have the choice of automated credit card processing, or pre-pay billing to save time and stress, all while keeping billing current, consistent and professional.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Business Center – Real-time financial and operational reports give you the information you need to make important business decisions.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Staff Payroll Reports – Automatically generate staff payroll reports to increase your efficiency and save you time.
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "ForBusiness",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>