<template>
  <div class="container pt-5">
    <p><strong>FAQs</strong></p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> For Dog Walking and Pet Sitting Companies</p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> What is Pet Check?</p>
    <p class="text-start px-1">
      Pet Check manages every aspect of your business, allowing you to gain bottom-line efficiencies and topline growth. Tools in Pet Check include scheduling, staff mobile app with real-time schedule updates, real-time staff check in and check out of appointments using our barcode scanning technology, invoicing, optional credit card processing, and business reports. You’ll wonder how you ever got along without it.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How does the scanning work?</p>
    <p class="text-start px-1">
      Your customers are assigned a barcode number when they sign up. The barcode is placed in their home for your staff to scan in and out with their mobile device. Each time your staff makes a scan, your customers receive a real-time email alert, letting them know the appointment has started and has ended. Within the scan out email are the details of the appointment including staff comments and length of service.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How does the invoicing work?</p>
    <p class="text-start px-1">
      You have choices in how your customers are invoiced. Options include pre-pay and bill after service. You can choose to bill your customers every week, bill your pet sitting customers in advance, or have them make a deposit on their pet sitting. Invoice frequency options include weekly, bi-weekly, or monthly. Invoices are automatically generated, freeing up your time to focus on anything but invoicing. You can also set customers to a manual option where invoices are only generated when you create them. Pet Check can also process invoices with your customers’ credit cards, which can even be done automatically, freeing you up entirely from doing any invoicing.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> If I opt for automated invoicing what time does it run?</p>
    <p class="text-start px-1">
      If you opt for automatic invoicing for weekly or bi-weekly frequencies, invoicing will be run between 8:30pm and 10:30pm EST. That is also when credit cards will be processed, but only if you opted into that option as well.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1"> What credit cards are accepted by Check? </p>
    <p class="text-start px-1">
      Pet Check currently accepts Visa, MasterCard, and Discover Card.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How does the scheduling work?</p>
    <p class="text-start px-1">
      Dog walking and pet sitting companies can schedule one-time appointments or a single series of appointments. Once you schedule an appointment, your staff will have real-time access to it in their Pet Check mobile app. This will help cut down on the back and forth communication that occurs with ever-changing schedule changes.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How does the GPS tracking work?</p>
    <p class="text-start px-1">
      Pet Check only tracks the GPS path of your staff while they are scanned into a walk. Coordinates are taken every 2-3 minutes from the staff member’s app to show your customers where their pet was walked or to show that your staff stayed at their residence the entire visit (in the case of a cat visit). Once the appointment is complete, the GPS map is populated for you and your customers to see.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> Can my staff take photos while on an appointment?</p>
    <p class="text-start px-1">
      Your staff can take and send up to 3 photos for every appointment they conduct. Pet owners will love seeing pictures of their happy and safe pets while they are away!
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How much does Pet Check cost?</p>
    <p class="text-start px-1">
      Pet Check is the only software program created by a 12-year industry veteran. We created the category for GPS tracking and are industry leaders. Please visit our pricing (link) page for specific details or call us at 312-953-5999.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> Do I have to sign a contract to use Pet Check?</p>
    <p class="text-start px-1">
      Pet Check provides a month-to-month service. You can cancel at any time for any reason.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> For Dog Walkers and Pet Sitters</p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> How does the Pet Check mobile app work?</p>
    <p class="text-start px-1">
      The Pet Check mobile app was designed by pet professionals just like you. The app offers dynamic updates to your schedule in real time throughout the day, empowering you to make better use of your time. All of the customer address information and pet notes are in the app for you so you can focus on providing the best care possible.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">How do I scan in and scan out of my appointments with the mobile app?</p>
    <p class="text-start px-1">
      We made it quick and easy to scan in and out. Just click on the appointment you wish to scan into, click the scan icon, and point the camera at the customer barcode. Doing so will trigger a real-time email alert to your customer letting them know you have arrived or departed. It’s a great way to give your customers peace of mind that their pet is being taken care of. You can also make comments about your visit that will be sent with the scan out email alert.
    </p>

    <p class="text-start px-1">Does the Pet Check mobile app use data, and much battery does it consume?</p>
    <p class="text-start px-1">
      All GPS-based and real-time mobile apps use data to operate. Over the years, we have considerably improved the amount of battery consumption the app requires. However, you must be conscious of the number of walks you do per day and the amount of battery you require for non-Pet Check activities. If you do a large number of appointments or are a heavy user of your mobile device, you should consider an external battery pack in the event you need to recharge while being out in the field.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">How do I receive my payroll reports?</p>
    <p class="text-start px-1">
      If your dog walking or pet sitting company uses that feature, they can have your payroll reports emailed to you. You can also login to the website to see that information, along with your profile, schedule, and other helpful information.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">For Pet Owners</p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  How do I login?</p>
    <p class="text-start px-1">
      Your dog walking or pet sitting company will create your Pet Check account for you and send you your username and password. Usernames are typically your email address. Once you have that information, you can log in and manage your account. You can also update your profile or pet information, request services, view past services, pay and view invoices.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  Can I log in to the Pet Check mobile app?</p>
    <p class="text-start px-1">
      The Pet Check mobile app is currently only for use by the dog walkers and pet sitters. Pet Check is working on a pet owner app, which should be available soon.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  Why do I have a barcode at my residence?</p>
    <p class="text-start px-1">
      The barcode you received is what your dog walker or pet sitter will use to scan in and to scan out of every appointment. Each time they scan you will receive a real-time email alert, which means you don’t have to wait until you get home to read a hand written checklist of the day’s visit/walk. You will have the peace of mind that your pet was taken care of while you were gone. The scan out email will contain important information about the visit, including any walker comments.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1"> Where do I see the GPS map of my appointment?</p>
    <p class="text-start px-1">
      You can log in to your account on the Pet Check website to view your appointment GPS maps. Simply click on the appointment you would like to view.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  How do I request a service from my dog walker or pet sitter?</p>
    <p class="text-start px-1">
      To request a service from your dog walker or pet sitter simply log on to your Pet Check account on the website and click ‘Request Service.’ Once approved by your provider, you will receive a confirmation email. You can view your schedule at any time on the website.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  How do I change or cancel an existing appointment?</p>
    <p class="text-start px-1">
      At the current time, all changes or cancellations must be requested directly to your service provider.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  How do I pay my invoice?</p>
    <p class="text-start px-1">
      You can login to your Pet Check account on the website to view and pay your invoices. Please note that some providers will sign you up for automatic invoice processing on your credit card. That’s just one less thing for you to worry about when it comes to your pet’s safety and happiness.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  What credit cards does Pet Check accept?</p>
    <p class="text-start px-1">
      We currently accept Visa, MasterCard, and Discover cards.
    </p>
    <p>&nbsp;</p>

    <p class="text-start px-1">  Why am I not receiving the scan in or scan out email alerts?</p>
    <p class="text-start px-1">
      If you have never or no longer are receiving the scan in or scan out email alerts, it is most likely due to an email security issue. Check to make sure alerts@petchecktechnology.com is on your safe senders list and make sure they emails are not going into your spam folder. You may need to contact your network administrator for help.
    </p>
    <p>&nbsp;</p>

  </div>
</template>

<script>
export default {
  name: "FAQs",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>