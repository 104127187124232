<template>
  <div class="container pt-5 ">
    <p><strong>ABOUT</strong></p>
    <p>&nbsp;</p>

    <div class="row d-flex">
      <div class="col-8">
        <p class="text-start px-1">
          “I needed a better way to run my dog walking and pet sitting business, so I assembled a diverse team – pet care pros and programming experts – and created Pet Check.”
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          I completely loved running my dog walking and pet sitting business during the first 10 years. However, as it grew to about 10 employees, we started running into problems. I still loved working with the pets, but it was all the details – scheduling, invoicing, communication, and staff management – that bogged the business down.
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          Spreadsheets were fine at first, but became an inefficient business tool as we grew. My customers requested better communication, and my dog walkers wanted more accessible scheduling. I needed a more streamlined and accountable approach to running the business.
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          I knew I wasn’t the only one with these problems, but no one had built a comprehensive pet care software solution. So I assembled a diverse team – pet care pros and programming experts – and created Pet Check.
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          Pet Check is a patented, all-in-one mobile and web tool that helps dog walking and pet sitting companies, their staff, and the pet owner. By combining simple software with a mobile app, every visit is verified with the real-time certainty of on-site mobile check-ins, GPS tracking, and pet owner email alerts. Staff schedules are immediately updated and easy to access. Invoicing is automated with optional credit card processing.
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          Everyone involved benefits from 100% accountability. Business owners are connected to a platform that runs their entire business. With Pet Check, there is no longer any worry about schedule changes, which means staff can focus on delivering the best pet care possible. Most importantly, pet owners can rest assured that their furry or feathered friends are healthy and happy.
        </p>
        <p>&nbsp;</p>
        <p class="text-start px-1">
          Doug Simon
        </p>
        <p class="text-start px-1">
          Founder, pet care pro, & animal lover
        </p>
      </div>
      <div class="col">
        <img class="about-pic" src="../../../assets/Doug6.jpg" alt="pic">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>
  .about-pic{
    width: 100%;
  }
</style>