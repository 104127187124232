<template>
  <div class="container pt-5">
    <p><strong>  Pet Check For Pet Owners
    </strong></p>
    <p>&nbsp;</p>
    <p class="text-start px-1">  Pets are members of the family, and pet owners demand more than a simple checklist on their kitchen countertop.</p>
    <p class="text-start px-1">
      The moment their pet is cared for they receive an email and mobile push notification with the details including a walk route, helpful notes like “running low on food,” and even photos! They look forward to seeing their special companion is happy, healthy and safe.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">  FEATURES</p>
    <p class="text-start px-1">
      Accountability – All of our features are designed to give pet owners the accountability they demand and deserve. Pet Check is just like pet owners in that we to make sure all pets are happy and safe.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Easy Account Management – Pet owners can easily manage their account online or via mobile app, including their schedule, feeding information, veterinarian information, invoicing, and more. Pet owners can leave notes for their walkers or sitters to keep them informed of important updates.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Scheduling – Pet owners can request appointments from the dog walking and pet sitting companies through the Pet Check scheduling tool. Confirmation will be returned via email, assuring that your pet will be taken care of when you need it most.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Verified Check-ins – With Pet Check, you’ll never again wonder what time your dog walker or pet sitter arrived and departed. Caregivers use their mobile device and the barcode placed in your residence to scan in and to scan out, giving you the exact time of care. No more waiting until you get home to read the handwritten checklist and wondering if it was accurate.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Pet Owner Email Alerts – Upon arrival and departure of every appointment, pet owners receive an email alert with important details of the visit. Use this helpful information to plan your day.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      GPS Visit Tracking – Now that you know the exact time your dog walker or pet sitter has arrived, you want to make sure your pet got the exercise you and your dog walker agreed to. No more nightmare stories that pets weren’t taken out of the house or were only taken out to the corner and back. Our maps show time elapsed points of where your pet was walked.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Pet Photos – Now that you know your pet is safe, let’s have some fun with photos! Your dog walker or pet sitter can take photos during the visit for you to see just how happy and safe your pet is with your caregiver.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Easy to Use Billing – Your dog walking and pet sitting companies can set you up for a billing method that works best for you. You will have full transparency and can log in anytime to see and even pay your bills with a credit card. Pet Check can even automate your bills, giving you one less thing to worry about and more time to focus on your pet.
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "ForPetOwners",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>