<template>
  <div class="footer-div">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="footer-card">
            <h6>ABOUT</h6>
            <ul>
              <li>
                <router-link to="/about">About</router-link>
              </li>
              <li>
                <router-link to="/press">Press</router-link>
              </li>
              <li>
                <router-link to="/pricing">Pricing</router-link>
              </li>
              <li>
                <router-link to="/for-business">Pet Check for business</router-link>
              </li>
              <li>
                <router-link to="/for-dog-walkers">Pet Check for dog walkers</router-link>
              </li>
              <li>
                <router-link to="/for-pet-owners">Pet Check for pet owners</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-card">
            <h6>HELP</h6>
            <ul>
              <li>
                <router-link to="/contact">Contact</router-link>
              </li>
              <li>
                <router-link to="/refund-and-cancellation">Refund and cancellation policy</router-link>
                <!--                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Refund and cancellation policy</a>-->
              </li>
              <li>
                <router-link to="/faqs">FAQs</router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">Privacy policy</router-link>
                <!--                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Privacy policy</a>-->
              </li>
              <li>
                <router-link to="/terms-and-conditions">Terms and conditions</router-link>
                <!--                  <a href="https://dashboard.petchecktechnology.com" target="_blank">Terms and conditions</a>-->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-card bordered-card">
            <h6>LATEST BLOG POSTS</h6>
            <ul>
              <li>
                <a href="https://dashboard.petchecktechnology.com" target="_blank">23 November 2015</a>
              </li>
              <li class="bordered-li">
                <a href="https://dashboard.petchecktechnology.com" target="_blank">Should I start my own dog walking business?</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-card bordered-card">
            <h6> </h6>
            <ul>
              <li>
                <a href="https://dashboard.petchecktechnology.com" target="_blank">29 September 2015</a>
              </li>
              <li class="bordered-li">
                <a href="https://dashboard.petchecktechnology.com" target="_blank">Why Your Business Needs Pet Check Technology</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fin-footer-div">
    <div class="container">
      <div class="row align-items-center">
        <div class="col copyright">
          <p>© 2021 Pet Check Technology. All Rights Recovered.</p>
        </div>
        <div class="col-2 ">
          <div class="logo">
            <img src="../../assets/logo-petCheck.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style scoped>
.footer-div{
  background: #035487;
  text-align: start;
  color: #f9f9f9;
}

.footer-card h6{
  font-weight: bold;
  /*line-height: 16px;*/
  min-height: 19px;
}

.footer-card > ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.footer-card a{
  color: #f9f9f9!important;
}

.bordered-card li{
  padding-left: 10px;
}

.bordered-li{
  border-left: 1px solid #f9f9f9;
}

.fin-footer-div{
  background: #007fb3e6;
  color: #f9f9f9;
}

.fin-footer-div .row{
  padding: 5px 0;
}

.copyright{
  text-align: start;
}

.logo img{
  width: 100%;
}
</style>