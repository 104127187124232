<template>
  <div class=" fist-cont">
    <div class="container">
      <div class="row justify-content-between head">
        <div class="col-4 col-md-4 col-sm-5 col-xs-8">
          <div class="logo">
            <router-link to="/"><img src="../../assets/logo-petCheck.png" alt=""></router-link>
          </div>
        </div>
        <div class="col-6 col-md-4 col-sm-5 col-xs-4">
          <button class="no-back-button">
            <a href="https://dashboard.petchecktechnology.com" target="_blank">Login</a>
          </button>
          <button class="no-back-button">
            <a href="https://dashboard.petchecktechnology.com" target="_blank">Sign up</a>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 col-sm-12">
          <div class="description-part">
            <h2 id="main-header">Run Every Aspect of your dog walking business</h2>
            <p>The only pet care management software built by pet pros</p>
            <button>
              <a href="https://dashboard.petchecktechnology.com" target="_blank">SIGN UP NOW</a>
            </button>
          </div>
        </div>
      </div>
      <div class="row scroll-btn" ref="scrollHere">
        <div class="col">
          <button class="no-back-button" @click="scrollDown('scrollHere')">
            <i class="fas fa-2x fa-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods:{
    scrollDown(refName){
      let element = this.$refs[refName];
      let top = element.offsetTop;
      window.scrollTo(0,top + 96);
    }
  }
}
</script>

<style scoped>

.no-back-button{
  background: none;
}

.fist-cont{
  background: url("../../assets/dog-min.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #f9f9f9;
}

#main-header{
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
}

.description-part{
  display: flex;
  justify-content: center;
  text-align: start;
  flex-direction: column;
  padding: 30px;
  height: 500px;
  align-content: center;
}


@media (max-width: 700px) {
  .description-part, .scroll-btn{
    display: none;
  }
}
@media (max-width: 575px) {
  .head img{
    width: 100%;
  }
  .row{
    padding: 5px 0;
  }
}

</style>