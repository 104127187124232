import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import TermsAndConditions from '../views/components/footer-links/TermsAndConditions'
import PrivacyPolicy from "../views/components/footer-links/PrivacyPolicy";
import RefundAndCancellation from "../views/components/footer-links/RefundAndCancellation";
import Contact from "../views/components/footer-links/Contact";
import FAQs from "../views/components/footer-links/FAQs";
import About from "../views/components/footer-links/About";
import ForPetOwners from "../views/components/footer-links/ForPetOwners";
import ForDogWalkers from "../views/components/footer-links/ForDogWalkers";
import ForBusiness from "../views/components/footer-links/ForBusiness";
import Pricing from "../views/components/footer-links/Pricing";
import Press from "../views/components/footer-links/Press";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: PrivacyPolicy
  },
  {
    path: '/refund-and-cancellation',
    name: 'Refund',
    component: RefundAndCancellation
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/for-pet-owners',
    name: 'ForPetOwners',
    component: ForPetOwners
  },
  {
    path: '/for-dog-walkers',
    name: 'ForDogWalkers',
    component: ForDogWalkers
  },
  {
    path: '/for-business',
    name: 'ForBusiness',
    component: ForBusiness
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/press',
    name: 'Press',
    component: Press
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
