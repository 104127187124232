<template>
  <div class="container pt-5">
    <p><strong>Pet Check for Dog Walkers and Pet Sitters</strong></p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Don’t let confusing schedules and unverified visits be an excuse for poor service. The Pet Check mobile app keeps dog walkers and pet sitters up to the second on their ever-changing and sometimes hectic schedules. All of the information your staff needs to wow customers is at their fingertips. Simple, on the spot mobile scanning confirms their time with the pet, and GPS maps track every moment of the pet's whereabouts, allowing them to focus fully on caring for the pets.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">  FEATURES</p>
    <p class="text-start px-1">
      Real-Time Scheduling – We understand that schedules can change in the blink of an eye. That’s why we designed the Pet Check mobile app in real-time. Any change that is made is automatically updated in the app to reduce miscommunications and to make planning your day easy. The mobile app contains all of the pet owner and pet information you need to provide the pet care your customers demand.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Verified Check-ins – You want to show your customers the great job you are doing, and the Pet Check app can do that by telling them exactly when you arrive and depart. Simply scan in and scan out of every residence using the barcode and you will give your customers the information they need at the time they need it. Your customers won’t have to second guess when or if you arrived to take care of their pet.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      GPS Visit Tracking – Not only do pet owners want to know when you arrive and depart from their residence, they want the assurance that their pet was walked or transported for the time allotted. Our GPS maps show a time sequence of where the pet was taken during the appointment.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Pet Photos – Sitters are be able to send photos to pet owners in the daily email to show owners their happy and safe pets while on the visit. Your customers will love seeing their pets while they are away.
    </p>
    <p>&nbsp;</p>
    <p class="text-start px-1">
      Staff Payroll Reports – Pet Check automatically generates staff payroll reports to ensure accurate and timely payments.
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "ForDogWalkers",
  created() {
    document.documentElement.scrollTop = 0;
  }
}
</script>

<style scoped>

</style>