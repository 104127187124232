<template>
  <div class="container pt-5">
    <p><strong>PRESS</strong></p>
    <p>&nbsp;</p>

    <p class=" px-1"> Don’t just take our word for it. <br> Look at what professionals are saying about our solutions and how they’ve enhanced the way they do business.</p>

    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  name: "Press"
}
</script>

<style scoped>

</style>